import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProductPageTemplate from "../../components/productPageTemplate"

const BerntPage = () => {
  const query = useStaticQuery(graphql`
    query berntQuery {
      mainImage: file(relativePath: { eq: "wash-container.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const product = {
    name: "Bernt",
    mainImage: query.mainImage.childImageSharp.fluid,
    description:
      "Denna modell av utomhus-cykeltvätt kan användas året runt. Den är försedd med låsbara dörrar och cykeltvätten är placerad invändigt. Klarar tvättning i tempraturer ner till minus 42 grader.",
    pdfLink: "/cykeltvätten.se_Bernt_produktblad.pdf",
    facts: (
      <ul>
        <li>Tillverkad i rostfritt 18,8 stål</li>
        <li>90 l integrerad tank</li>
        <li>1 % tvättmedelsblandning</li>
        <li>5 kg vattentryck (alla förekommande cykellager klarar ca 6 kg)</li>
        <li>Högeffektiva borstar</li>
        <li>Rullhjul för förflyttning</li>
        <li>Spillvatten återfiltreras</li>
        <li>Kontrollerbart utsläpp</li>
        <li>
          Plastdetaljer tillverkade i slagtålig plast samt återvinningsvänlig
        </li>
      </ul>
    ),
    specs: (
      <ul>
        <li>Vattentank volym: 120 liter</li>
        <li>Elförbrukning: 1 200-3 100 watt</li>
        <li>Tvättkapacitet/tank: 110 tvättar</li>
        <li>Tvättkapacitet/timme: 30 tvättar</li>
        <li>Förbrukning/tvätt: 1-2 deciliter</li>
        <li>Borstar: 2 st</li>
        <li>Munstycken: 6 st</li>
        <li>TILLVAL: Kamera Kodlås</li>
        <li>Betalning: Kortläsare, Swish</li>
        <li>
          MÅTT UTVÄNDIGT [MM]: Längd: 6 500 Bredd: 2 400 Höjd: 2 400 (Glasparti
          med öppning, bredd: 1 400)
        </li>
        <li>
          MÅTT CYKELTVÄTT [MM]: Längd: 1 400 (3 100 inkl. ramp) Bredd: 1 100
          Höjd: 1 150
        </li>
      </ul>
    ),
  }

  return <ProductPageTemplate product={product} />
}
export default BerntPage
